import Home from "../components/layouts/home"
// @ts-ignore
import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { FluidObject } from "gatsby-image"
import "./../css/thinwood.scss"

export const query = graphql`
  query {
    allImageSharp {
      edges {
        node {
          fluid(webpQuality: 70, jpegQuality: 80, srcSetBreakpoints: [300, 600, 800, 1200, 1600, 1920], fit: COVER, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
          fixed {
            originalName
          }
        }
      }
    }
  }
`


interface Data {
  allImageSharp: {
    edges: [
      {
        node: {
          fluid: FluidObject,
          fixed: {

            originalName: string
          }
        }
      },

    ]
  }
}

interface Props {
  data: Data;
}

export default function IndexRoute(props: Props) {
  // const selectedNode = props.data.allMarkdownRemark.edges[0];
  // const heroImage = selectedNode.node.frontmatter.hero_image.childImageSharp.fluid;
  // const landingPageSections = selectedNode.node.frontmatter.landingpageSections;
  const headerImageLeft = props.data.allImageSharp.edges.find(edge => edge.node.fixed.originalName === "thinwood-header-left.png")
  const headerImageRightBottom = props.data.allImageSharp.edges.find(edge => edge.node.fixed.originalName === "thinwood-header-right-bottom.png")
  const headerImageRightTop = props.data.allImageSharp.edges.find(edge => edge.node.fixed.originalName === "thinwood-header-right-top.png")
  const collectionImage = props.data.allImageSharp.edges.find(edge => edge.node.fixed.originalName === "collection.png")
  const somethingImage = props.data.allImageSharp.edges.find(edge => edge.node.fixed.originalName === "soemthing-detail.png")
  const newdefinedImage = props.data.allImageSharp.edges.find(edge => edge.node.fixed.originalName === "newdefined.png")
  const leaderImage = props.data.allImageSharp.edges.find(edge => edge.node.fixed.originalName === "leader.png")
  const inlonaImage = props.data.allImageSharp.edges.find(edge => edge.node.fixed.originalName === "inlona.png")
  const newDefImage = props.data.allImageSharp.edges.find(edge => edge.node.fixed.originalName === "new-defined.png")
  const bgOne = props.data.allImageSharp.edges.find(edge => edge.node.fixed.originalName === "2021_09_MeM_O_Thinwood_Webpage_Holz1a.jpg")
  const bgTwo = props.data.allImageSharp.edges.find(edge => edge.node.fixed.originalName === "2021_09_MeM_O_Thinwood_Webpage_Holz2a.jpg")
  const bgThree = props.data.allImageSharp.edges.find(edge => edge.node.fixed.originalName === "2021_09_MeM_O_Thinwood_Webpage_Holz3a.jpg")
  return (
    <Home>
      <div className={"hero-image section"}>
        <div className={"hero-image-left"}>
          <Img fluid={headerImageLeft.node.fluid} />
        </div>
        <div className={"hero-image-right"}>
          <Img className={"hero-image-right-image"} fluid={headerImageRightTop.node.fluid} />
          <Img className={"hero-image-right-image"} fluid={headerImageRightBottom.node.fluid} />
        </div>
      </div>
      <div className={"new-defined"}>
        <h2>
          Holzbrillen neu definiert
        </h2>
        <div className={"new-defined-block"}>
          <div className={"new-defined-image"}>

          </div>
          <div className={"new-defined-text"}>
            <Img className={"new-defined-image-float"} fluid={newDefImage.node.fluid} />
            <p>
              Thinwood Fassungen sind hoch entwickelte technische Produkte. Wir haben die Lösung entwickelt, um
              Brüchigkeit von Holzbrillen zu überwinden.
            </p>
            <p>
              Wir sind fest davon überzeugt, dass durch Handwerkskunst und Ideenreichtum einzigartige Produkte
              erschaffen werden können, aus welchen sich eine neue Freude, basierend auf einer Verbindung von Qualität,
              Zweckdienlichkeit und Kreativität des Produkts, ergibt.
            </p>
          </div>
        </div>
      </div>
      <div className={"collection"}>
        <div className={"collection-text"} style={{
          backgroundImage: `url("${bgOne.node.fluid.src}")`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: "cover"
        }}>
          <h2>
            Collection
          </h2>
          <p>
            Thinwood steht für nachhaltige, moderne und elegante Fassungsformen, die die Einzigartigkeit eines jeden
            Gesichtes betonen und den Look des Trägers vervollständigt.
          </p>
          <p>
            Thinwood Fassungen verkörpern ein zeitloses und zukunftssicheres Design und sind das perfekte Accessoire für
            die kommenden Jahre.
          </p>
        </div>
        <div className={"collection-image"}>
          <Img className={""} fluid={{ ...collectionImage.node.fluid, aspectRatio: 1 }} />
        </div>
      </div>
      <div className={"weight"}>
        <div className={"weight-image"}>
          <Img className={""} fluid={{ ...somethingImage.node.fluid, aspectRatio: 1 }} />
        </div>
        <div className={"weight-text"}
             style={{
               backgroundImage: `url("${bgTwo.node.fluid.src}")`,
               backgroundRepeat: `no-repeat`,
               backgroundSize: "cover"
             }}
        >
          <p>
            Thinwood Fassungen haben ein Gewicht von nur 3 bis 5 Gramm. Trotz dieses geringen Gewichtes zeichnen sich
            alle Fassungen durch Flexibilität und Widerstandsfähigkeit aus, was Langlebigkeit garantiert.
          </p>
          <p>
            Am Tragekomfort der Thinwood Fassungen wurde bei uns im Haus selbstverständlich auch nicht gespart. Wir
            nutzen für die Nasenpads medizinisches Silikon und können somit ein wunderbares Tragegefühl für den Kunden
            garantieren.
          </p>
          <p>
            Thinwood Fassungen lassen sich komplett in Farbe, Größe Ihrer Individualität anpassen und sind in über 27
            Farben erhältlich.
          </p>
        </div>
      </div>
      <div className={"newdefinedImage"}>

        <div className={"newdefinedImage-text"}
             style={{
               backgroundImage: `url("${bgThree.node.fluid.src}")`,
               backgroundRepeat: `no-repeat`,
               backgroundSize: "cover"
             }}>

          <p>
            Wir von Thinwood haben die Holzbrille neu definiert! Thinwood Fassungen sind hoch entwickelte technische
            Produkte. Wir haben die Lösung, um Brüchigkeit von Holzbrillen zu überwinden, entwickelt.
          </p>
          <p>
            Das Ergbnis ist ein holzbasierter Komposit-Werkstoff, der eine umweltfreundliche technische Lösung ist.
          </p>
          <p>
            Wir verwenden dafür einen Hightech-Werkstoff, der in der Formel 1 und dem Weltall erprobt ist und mit grüner
            Energie hergestellt wird.
          </p>
          <p>
            Für unsere Thinwood Fassungen werden ausschließlich pflanzenbasierte, recycelte und wiederverwertbare
            Materialien, die ausschließlich von zertifizierten europäischen Herstellen bezogen werden, verwendet.
          </p>
        </div>
        <div className={"newdefinedImage-image"}>
          <Img className={""} fluid={{ ...newdefinedImage.node.fluid, aspectRatio: 1 }} />
        </div>
      </div>
      <div className={"team"}>

        <div className={"team-ceo"}>
          <div className={"team-ceo-image"}>
            <Img fluid={leaderImage.node.fluid} />
            <p><span className={"bold"}>ARNOLD</span>, CEO</p>
            <p><span className={"bold"}>SOPHI</span>, Sales manager</p>
          </div>
          <div>
          </div>
        </div>
        <div className={"team-production"}>
          <h2>About Us</h2>
          <p>2017 wurde Thinwood in Budapest gegründet, 6 Jahre nach dem ersten Prototyp. Inzwischen findet man unsere
            Produkte bei führenden Optikern Ungarns und darüber hinaus dürfen wir uns über wachsende Beliebtheit u.a. in
            Deutschland, Italien, Luxemburg, Belgien, Kanada und den USA freuen.</p>
          <div className={"team-production-image"}>
            <Img fluid={inlonaImage.node.fluid} />
            <p><span className={"bold"}>ILONA</span>, Production manager</p>
          </div>
        </div>
      </div>
      <div className={"our-work"}>
        <h2>Our work</h2>
        <p>
          Unsere Thinwood Produkte bestehen aus über 50 handwerklichen Herstellungsschritten und werden alle von Hand
          gefertigt. Diese präzise Handarbeit ermöglicht, dass wir höchste Perfektion und Qualität erreichen und wir
          sind absolut davon überzeugt, dass nur die Liebe zur handwerklichen Fertigkeit, die in unserem Haus sehr
          großgeschrieben wird, uns unserem Ziel - Ihre besten Brillen in bester Qualität - anbieten zu können,
          erreichen lässt.
        </p>
      </div>
      <div className={"contact"}>
        <h2>Wir präsentieren ihnen gern die neue kollektion vor ort.</h2>
        <p>Vereinbaren Sie dazu einfach einen Termin bei einem unserer Partner vor Ort.</p>
        <a href={"mailto:service@mira-and-me.de"}>Termin vereinbaren</a>
        <p>Oder Rufen sie uns an.</p>
        <a className={"contact-phone"} href={"tel:08007236059"}>0800 723 60 59</a>
        <p>Alternativ können Sie sich den Katalog auch online ansehen.</p>
        <a href={"https://www.thinwood.hu/wp-content/uploads/2023/08/Thinwood-SzemuvegSzabosag-katalogus-2324.pdf"} target="_blank" rel="noopener">Zum Katalog</a>
      </div>

    </Home>
  )
}
